<template>
    <div :class="classList">
        <div class="phone-input-wrapper">
            <div class="code-lists" v-click-outside="hide">
                <div class="selected" @click="showList = !showList">
                    <span class="flag">
                        <img v-if="selected" :src="require(`@/assets/flags/${selected.country_code.toLowerCase()}.png`)" :alt="selected.name" />
                    </span>
                    <svg class="caret" xmlns="http://www.w3.org/2000/svg" width="15.807" height="8.865" viewBox="0 0 15.807 8.865">
                        <g>
                            <g>
                                <g>
                                    <path d="M238.206 36.726l.692-.692 1.657-1.657 2-2 1.731-1.731c.28-.28.564-.559.842-.842l.011-.011a.982.982 0 0 0 0-1.367l-.692-.692-1.657-1.657-2-2-1.731-1.731c-.28-.28-.559-.564-.842-.842l-.011-.011a1 1 0 0 0-.683-.283.968.968 0 0 0-.966.966 1 1 0 0 0 .283.683l.692.692 1.658 1.649 2 2 1.731 1.731c.28.28.557.565.842.842l.011.011v-1.365l-.692.692-1.657 1.657-2 2-1.731 1.732c-.28.28-.564.559-.842.842l-.011.011a1 1 0 0 0-.283.683.968.968 0 0 0 1.649.686z" transform="translate(37.007 -236.556) rotate(90) translate(236.556 21.2) translate(0 0) translate(-236.556 -21.2)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <transition name="fade">
                    <div class="list" v-if="showList">
                        <div class="code" v-for="(code, index) in codes" :key="index" @click="selectCode(code)">
                            <img :src="require(`@/assets/flags/${code.country_code.toLowerCase()}.png`)" :alt="code.name" />
                            <span>{{ code.name }}</span>
                            <span class="prefix"><b>{{ code.phone_code }}</b></span>
                        </div>
                    </div>
                </transition>
            </div>
            <input type="text" class="form-control" v-model="phone" @input="findMatch" :placeholder="$t('Phone')" />
        </div>
    </div>
</template>

<script>
import codes from "@/js/codes";

export default {
    props: {
        classList: {
            required: true
        },
        phone: {
            default: ''
        }
    },
    data() {
        return {
            codes: codes,
            selected: null,
            showList: false
        }
    },
    watch: {
        phone() {
            this.findMatch();
        }
    },
    methods: {
        hide() {
            this.showList = false;
        },
        selectCode(code) {
            this.selected = code;
            this.$emit('update', code.phone_code);
            this.showList = false;
        },
        findMatch() {
            let match = this.codes.find((item) => this.phone.startsWith(item.phone_code));

            if (match) {
                this.selected = match;
                this.$emit('update', this.phone);
            } else {
                this.selected = null;
            }
        }
    },
    mounted() {
        if (this.phone) {
            this.findMatch();
        }
    }
}
</script>